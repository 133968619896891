import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';

import "@/assets/styles/main.scss"
import "@fontsource/figtree";
import 'primevue/resources/themes/aura-light-green/theme.css'

const app = createApp(App)

app.config.globalProperties.$filters = {
  currencyPHP(value) {
    const absValue = Math.abs(value);
    const returnString = Number(absValue).toLocaleString('en-US', {
      style: 'currency',
      currency: "PHP",
      minimumFractionDigits: 2
    });
    if(value < 0) {
      return '-' + returnString;
    } else {
      return returnString;
    }
  },

  formatDate(dateInUnix) {
    const date = new Date(Number(dateInUnix))
    return date.toLocaleString('en-us',{ month: 'short', day: 'numeric', year: 'numeric' });
  },

  formatString(str) {
    if (!str) return '';
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  },
}

app.use(PrimeVue);
app.use(createPinia())
app.use(router)
app.directive('tooltip', Tooltip);
app.mount('#app')
